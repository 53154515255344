import React from 'react';
import {
  Box, CircularProgress, useTheme, makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
  },
  default: {
    backgroundColor: theme.palette.background.default,
  },
}));

function Loading(props) {
  const { backgroundVariant, flexed } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      className={
        `
        flex-container-center 
        ${flexed ? 'flex-1' : ''} 
        ${classes[backgroundVariant] || classes.default}
        `
      }
    >
      <CircularProgress size={ theme.spacing(10) } />
    </Box>
  );
}

Loading.defaultProps = {
  backgroundVariant: 'default',
  flexed: false,
};

Loading.propTypes = {
  backgroundVariant: PropTypes.oneOf(['paper', 'default']),
  flexed: PropTypes.bool,
};

export default Loading;
