export const FILTER_REQUESTS = 'FILTER_REQUESTS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const FILTER_TICKET_NUMBER = 'FILTER_TICKET_NUMBER';
export const CLEAR_TICKET_NUMBER = 'CLEAR_TICKET_NUMBER';
export const FILTER_CUSTOMER = 'FILTER_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';
export const FILTER_DATA_CENTRE = 'FILTER_DATA_CENTRE';
export const FILTER_STATUS = 'FILTER_STATUS';
export const FILTER_FROM_DATE = 'FILTER_FROM_DATE';
export const FILTER_TO_DATE = 'FILTER_TO_DATE';
export const REQUEST_LOADING = 'REQUEST_LOADING';
export const SET_REQUEST = 'SET_REQUEST';
export const SET_DRAWER_ITEM = 'SET_DRAWER_ITEM';
export const SET_DRAWER_ID = 'SET_DRAWER_ID';
export const RESET_FILTER = 'RESET_FILTER';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';
export const SET_STATUSES_LOADING = 'SET_STATUSES_LOADING';
export const SET_STATUSES = 'SET_STATUSES';

// reducer state constants
export const ACCESS = 'access';
export const SERVICE = 'service';

export const TICKET_NUMBER = 'ticketNumber';
export const CUSTOMER = 'customer';
export const DATA_CENTRE = 'dataCentre';
export const FROM_DATE = 'fromDate';
export const TO_DATE = 'toDate';
export const STATUS = 'status';
export const AIRTRUNK_ACCESS_NUMBER = 'airtrunkAccessNumber';

// date filters
export const START = 'start';
export const END = 'end';

// Notistack
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
