import {
  ACCESS,
  SERVICE,
  FILTER_REQUESTS,
  CLEAR_FILTER,
  RESET_FILTER,
  REQUEST_LOADING,
  SET_REQUEST,
  START,
  END,
} from '../action-constants';
import { ALL_OR_NO_SELECTION } from '../../util/constants';

const DEFAULT_DATE_FILTER = {
  start: null,
  end: null,
};

const DEFAULT_FILTERS = {
  ticketNumber: '',
  customer: null,
  dataCentre: null,
  status: ALL_OR_NO_SELECTION,
  fromDate: DEFAULT_DATE_FILTER,
};

const ACCESS_DEFAULT_FILTERS = {
  ...DEFAULT_FILTERS,
  toDate: DEFAULT_DATE_FILTER,
  airtrunkAccessNumber: ''
};

const initialState = {
  [ACCESS]: {
    filters: ACCESS_DEFAULT_FILTERS,
    data: [],
    isLoading: false,
    total: 0,
  },
  [SERVICE]: {
    filters: DEFAULT_FILTERS,
    data: [],
    isLoading: false,
    total: 0,
  },
};

const filterRequests = (state, action) => {
  const {
    requestType, payload, filter, params,
  } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters: {
        ...state[requestType].filters,
        [filter]: (params === START || params === END) ? {
          ...state[requestType].filters[filter],
          [params]: payload,
        } : payload,
      },
    },
  };
};


const clearFilter = (state, action) => {
  const { requestType, filter } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters: {
        ...state[requestType].filters,
        [filter]: '',
      },
    },
  };
};

const setLoading = (state, action) => {
  const { requestType, payload } = action;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      isLoading: payload,
    },
  };
};

const setRequest = (state, action) => {
  const { payload, requestType } = action;
  const { data, total } = payload;
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      data,
      total,
    },
  };
};

const resetFilter = (state, action) => {
  const { requestType } = action;
  const filters = {
    ...requestType === ACCESS ? ACCESS_DEFAULT_FILTERS : DEFAULT_FILTERS,
    status: ALL_OR_NO_SELECTION,
  };
  return {
    ...state,
    [requestType]: {
      ...state[requestType],
      filters,
    },
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FILTER_REQUESTS: {
      return filterRequests(state, action);
    }
    case CLEAR_FILTER: {
      return clearFilter(state, action);
    }
    case RESET_FILTER: {
      return resetFilter(state, action);
    }
    case REQUEST_LOADING: {
      return setLoading(state, action);
    }
    case SET_REQUEST: {
      return setRequest(state, action);
    }
    default:
      return state;
  }
};
