import { ACCESS, SERVICE } from './action-constants';

// Requests
export const accessFiltersSelector = state => state.requests[ACCESS].filters;
export const accessRequestsSelector = state => state.requests[ACCESS].data;
export const accessLoadingSelector = state => state.requests[ACCESS].isLoading;
export const accessTotalSelector = state => state.requests[ACCESS].total;

export const serviceFiltersSelector = state => state.requests[SERVICE].filters;
export const serviceRequestsSelector = state => state.requests[SERVICE].data;
export const serviceLoadingSelector = state => state.requests[SERVICE].isLoading;
export const serviceTotalSelector = state => state.requests[SERVICE].total;

export const ticketNumberFilter = (state, requestType) => state.requests[requestType].filters.ticketNumber;
export const customerFilter = (state, requestType) => state.requests[requestType].filters.customer;
export const dataCentreFilter = (state, requestType) => state.requests[requestType].filters.dataCentre;
export const fromDateFilter = (state, requestType) => state.requests[requestType].filters.fromDate;
export const fromDateStartFilter = (state, requestType) => state.requests[requestType].filters.fromDate.start;
export const fromDateEndFilter = (state, requestType) => state.requests[requestType].filters.fromDate.end;
export const toDateFilter = (state, requestType) => state.requests[requestType].filters.toDate;
export const toDateStartFilter = (state, requestType) => state.requests[requestType].filters.toDate.start;
export const toDateEndFilter = (state, requestType) => state.requests[requestType].filters.toDate.end;
export const statusFilter = (state, requestType) => state.requests[requestType].filters.status;
export const airtrunkAccessNumberFilter = (state, requestType) => state.requests[requestType].filters.airtrunkAccessNumber;

export const accessContactsSelector = state => state.requests[ACCESS].contacts.data;
export const accessContactsLoadingSelector = state => state.requests[ACCESS].contacts.isLoading;
export const accessContactsLoadedSelector = state => state.requests[ACCESS].contacts.isLoaded;

// Data - Drawer item
export const drawerIdSelector = state => state.data.drawerId;
export const drawerItemSelector = state => state.data.drawerItem;

// Data - Customer
export const customersSelector = state => state.data.customers.data;
export const customersLoadingSelector = state => state.data.customers.isLoading;
export const customersLoadedSelector = state => state.data.customers.isLoaded;

// Data - Data Centres
export const dataCentresSelector = state => state.data.dataCentres.data;
export const dataCentresLoadingSelector = state => state.data.dataCentres.isLoading;
export const dataCentresLoadedSelector = state => state.data.dataCentres.isLoaded;

// App
export const authStateSelector = state => state.app.authState;
export const notificationsSelector = state => state.app.notifications;

// Statuses
export const statusesLoadingSelector = state => state.statuses.isLoading;
export const accessStatusSelector = state => state.statuses[ACCESS];
export const serviceStatusSelector = state => state.statuses[SERVICE];
