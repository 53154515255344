import React, {
  useEffect, Suspense, lazy, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Error, Brightness4Outlined, Brightness7 } from '@material-ui/icons';
import { Tooltip, Fab, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { SnackbarProvider } from 'notistack';
import {
  green, red, yellow, blue,
} from '@material-ui/core/colors';
import DevTools from './redux/DevTools';
import ErrorBoundary from './components/shared/ErrorBoundary';
import { authStateSelector } from './redux/selectors';
import Loading from './components/shared/Loading';
import Login from './components/Login';
import { validateAuth } from './util/util';
import { SIGN_IN, SIGN_OUT } from './util/constants';
import { setAuthState } from './redux/actions/app';
import Notifier from './components/shared/Notifier';

const Content = lazy(() => import('./containers/TabNavigation'));

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    bottom: 50,
    left: 50,
  },
  white: {
    color: theme.palette.common.white,
  },
  success: {
    backgroundColor: green[500],
    color: '#fff',
  },
  error: {
    backgroundColor: red[500],
    color: '#fff',
  },
  warning: {
    backgroundColor: yellow[500],
    color: '#fff',
  },
  info: {
    backgroundColor: blue[500],
    color: '#fff',
  },
}));

function AppContent(props) {
  const {
    darkMode, toggleTheme, authState, setAuth,
  } = props;
  const classes = useStyles();
  const setSignIn = useCallback(() => {
    if (authState !== SIGN_IN) setAuth(SIGN_IN);
  }, [authState, setAuth]);
  const setSignOut = useCallback(() => {
    if (authState !== SIGN_OUT) setAuth(SIGN_OUT);
  }, [authState, setAuth]);
  const signOut = useCallback(() => {
    Auth.signOut().then().catch(error => {
      console.error(error);
      setSignOut();
    });
  }, [setSignOut]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case SIGN_IN:
          // This will only trigger with normal sign in flow.
          setSignIn();
          break;
        case SIGN_OUT:
          // This will only trigger with normal sign out flow.
          setSignOut();
          break;
        default:
          break;
      }
    });
    // On page load. check if session/tokens is available and valid
    // if valid, set sign in.
    // if invalid, set sign out.
    validateAuth(setSignIn, signOut);
  }, [setSignIn, setSignOut, signOut]);

  useEffect(() => {
    let authExpiryInterval = null;

    if (authState === 'signIn' && !authExpiryInterval) {
      authExpiryInterval = setInterval(() => {
        validateAuth(setSignIn, signOut);
      },
      // 1 minute intervals
      60 * 1000);
    } else if (authExpiryInterval) {
      clearInterval(authExpiryInterval);
    }
  }, [authState, signOut, setSignIn]);

  return (
    <>
      {authState === SIGN_OUT && (
        <Login />
      )}
      {authState === SIGN_IN && (
        <ErrorBoundary
          fallback={
            <Error />
          }
        >
          <Suspense
            fallback={
              <Loading />
            }
          >
            <SnackbarProvider
              classes={ {
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
              } }
            >
              <MuiPickersUtilsProvider utils={ MomentUtils }>
                <Notifier />
                <DevTools />
                <Content setSignOut={ setSignOut } />
                <Tooltip
                  title={ darkMode ? 'Use Light Theme' : 'Use Dark Theme' }
                >
                  <Fab
                    onClick={ toggleTheme }
                    className={ classes.fab }
                    color='primary'
                  >
                    {!darkMode && <Brightness4Outlined />}
                    {darkMode && <Brightness7 className={ classes.white } />}
                  </Fab>
                </Tooltip>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
          </Suspense>
        </ErrorBoundary>
      )}
    </>
  );
}


AppContent.defaultProps = {
  authState: null,
};

AppContent.propTypes = {
  authState: PropTypes.string,
  setAuth: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
  toggleTheme: PropTypes.func.isRequired,
};

const mapStateToProps = state => (
  { authState: authStateSelector(state) }
);

const mapDispatchToProps = dispatch => (
  { setAuth: event => dispatch(setAuthState(event)) }
);

export default connect(mapStateToProps, mapDispatchToProps)(AppContent);
