import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withOAuth } from 'aws-amplify-react';

const LoginButton = props => {
  const {
    OAuthSignIn, color, variant, size,
  } = props;
  return (
    <Button onClick={ OAuthSignIn } color={ color } variant={ variant } size={ size }>
      Sign in
    </Button>
  );
};

LoginButton.defaultProps = {
  color: undefined,
  variant: undefined,
  size: undefined,
};

LoginButton.propTypes = {
  OAuthSignIn: PropTypes.func.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
};


export default withOAuth(LoginButton);
