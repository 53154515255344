import { Auth } from 'aws-amplify';
import momentTz from 'moment-timezone';
import moment from 'moment';

// eslint-disable-next-line max-len
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const numberOnlyRegex = /^[0-9]*$/;

export const validateAuth = (success, failure) => Auth.currentSession()
  .then(success)
  .catch(failure);

export const convertInputTimezone = (momentDateTime, timezone) => (
  momentDateTime ? momentTz.tz(momentDateTime.format('YYYY-MM-DD HH:mm:ss'), timezone) : undefined
);

export const convertToLocalTimezone = (momentDateTime, timezone) => (
  momentDateTime ? momentTz.tz(momentDateTime, timezone || moment.tz.guess()) : undefined
);
