export const OPEN = 'open';
export const IN_PROGRESS = 'in_progress';
export const ACCEPTED = 'accepted';
export const COMPLETED = 'completed';
export const DECLINED = 'declined';
export const CANCELLED = 'cancelled';
export const CLOSED = 'closed';

export const SERVICE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
  [COMPLETED]: 'Completed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
};

export const NEW_SERVICE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
};

export const ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
  [CLOSED]: 'Closed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
};

export const NEW_ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
};

export const MAINTENANCE_STATUSES = {
  completed: 'Completed',
  scheduled: 'Scheduled',
};

export const CONTACT_TYPES = {
  approver: 'Approver',
  billing: 'Billing',
  maintenance: 'Maintenance',
  reporting: 'Reporting',
  site_access: 'Site Access',
  tape_management: 'Tape Management',
};

export const NOTIFICATION_TYPES = {
  datacentre: 'Data Centre',
  customer: 'Customer',
  data_floor: 'Data Floor',
  power_module: 'Power Module',
  rack: 'Rack',
};

export const DATE_FORMAT = 'll';
export const DATE_TIME_FORMAT = 'llll';
export const DATE_TIME_DRAWER_FORMAT = 'DD/MM/YYYY h:mm a z';

export const ALL_OR_NO_SELECTION = 0;

export const ACCESS_REQUEST_PRIORITY = [
  'low', 'normal', 'high', 'critical',
];

export const ACCESS = 'ACCESS';
export const ADDITIONAL = 'ADDITIONAL';

export const SIGN_OUT = 'signOut';
export const SIGN_IN = 'signIn';

export const API_NAME = process.env.REACT_APP_AWS_API_NAME;

export const maxCharacterLength = length => `Max length of ${length} characters reached`;
export const fieldRequired = field => `${field} is required`;
export const invalidBeforeCurrentDateTime = (fieldName, dateTime) => (
  `${fieldName} date time cannot before or be the same as ${dateTime}`
);

export const TO_DATE_AFTER_FROM_DATE = 'End date time must be after Scheduled the date time';
export const FROM_DATE_BEFORE_TO_DATE = 'Schedule date time must be before the End date time';
export const STATUS_INVALID = 'Request status is invalid';
export const REQUESTER_EMAIL_INVALID = 'Invalid email format';
export const RACKS_REQUIRED = 'Select at least one rack';
export const RACKS_INVALID = 'One or more of your racks has an invalid date';
export const PRIORITY_INVALID = 'Priority is required';
export const CONTACT_REQUIRED = 'Must have at least one Access contact or Additional contact';
export const ACCESS_CONTACT_SITE_ACCESS = 'One or more of your contacts do not have site access';
export const ACCESS_CONTACT_INDUCTION = 'One or more of your contacts do not have valid induction dates';
